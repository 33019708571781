import { signOut } from 'next-auth/react';
import router from 'next/router';
import APP_ROUTES from 'src/common/appRoutes';

const logoutClient = async () => {
  // Clear storage
  localStorage.clear();

  // Set cookie to clear from server
  document.cookie = 'refreshFailed=refreshTokenFailed; path=/;';

  signOut({ callbackUrl: APP_ROUTES.SIGN_IN });
  router.push(APP_ROUTES.SIGN_IN);
};

export { logoutClient };
