import https from 'https';
import axios, { AxiosError } from 'axios';
import { getSession } from 'next-auth/react';
import getConfig from 'next/config';
import router from 'next/router';
import APP_ROUTES from 'src/common/appRoutes';
import { CHATGPT_BASE_URL } from 'src/common/constants';
import { refreshAccessToken } from 'src/common/utils';
import { logoutClient } from 'src/utils/logoutClient';

const { publicRuntimeConfig = {} } = getConfig() || {};
const { NEXT_PUBLIC_BASE_URL, NODE_ENV } = publicRuntimeConfig;

function createAxiosClient(baseURL: string, contentType: string, base64Token?: string) {
  let agent;
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    failedQueue = [];
  };

  if (typeof window === 'undefined' && NODE_ENV !== 'production') {
    agent = new https.Agent({
      rejectUnauthorized: false
    });
  }

  const axiosClient = axios.create({
    baseURL,
    httpsAgent: agent, // This agent prevent ERR_TLS_CERT_ALTNAME_INVALID issue when in SSR development environment,
    headers: {
      'content-type': contentType
    }
  });

  axiosClient.interceptors.request.use(
    async (config) => {
      if (typeof window === 'undefined') return config;
      const accessToken = localStorage.getItem('token');
      if (base64Token) {
        config.headers['Chart-Authorization'] = base64Token;
      }
      if (!accessToken) return config;
      config.headers.Authorization = accessToken;

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axiosClient.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error: AxiosError) => {
      const originalRequest = error.config;
      if (error.response?.status.toString() === '401' && typeof window !== 'undefined') {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = token;
              return axiosClient(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        isRefreshing = true;
        const session = await getSession();
        const refreshToken = localStorage.getItem('refresh_token');

        if (refreshToken) {
          try {
            const refreshTokens = await refreshAccessToken(refreshToken);
            if (refreshTokens && refreshTokens.access_token) {
              localStorage.setItem('token', refreshTokens.access_token.toString());
              localStorage.setItem('refresh_token', refreshTokens.refresh_token.toString());

              // Retry queued requests
              processQueue(null, refreshTokens.access_token);

              // Retry origin request
              originalRequest.headers.Authorization = refreshTokens.access_token;
              return axiosClient(originalRequest);
            } else {
              // refresh failed
              processQueue(new Error('Token refresh failed'), null);
              if (session) {
                logoutClient();
              } else {
                router.push(APP_ROUTES.SIGN_IN);
              }
            }
          } catch (err) {
            processQueue(err, null);
            if (session) {
              logoutClient();
            } else {
              router.push(APP_ROUTES.SIGN_IN);
            }
            return Promise.reject(err);
          } finally {
            isRefreshing = false;
          }
        } else if (session) {
          logoutClient();
        } else {
          router.push(APP_ROUTES.SIGN_IN);
        }
      }
      return Promise.reject(error);
    }
  );
  return axiosClient;
}

const appAxios = createAxiosClient(NEXT_PUBLIC_BASE_URL, 'application/json');
const appAxiosAsk = createAxiosClient(CHATGPT_BASE_URL, 'application/json');
const appAxiosKeycloak = createAxiosClient(
  `${process.env.NEXT_PUBLIC_KEYCLOAK_URL}/realms/${process.env.NEXT_PUBLIC_KEYCLOAK_REALM}`,
  'application/x-www-form-urlencoded;charset=UTF-8'
);
const appAxiosChart = (base64Token: string) => {
  return createAxiosClient(NEXT_PUBLIC_BASE_URL, 'multipart/form-data', base64Token);
};

export { appAxios, appAxiosAsk, appAxiosKeycloak, appAxiosChart };
